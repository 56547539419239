<template>
  <div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Kit Pickup Status -->
        <span v-if="props.column.field === 'kitPickupStatus'">
          <span v-if="props.row.kitPickupStatus === 'Scheduled'">
            Kit Pickup scheduled
          </span>
          <span v-else-if="props.row.kitPickupStatus === 'Received'">
            Received at Navipoint
          </span>
          <span v-else>
            {{ props.row.kitPickupStatus }}
          </span>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="selectedBarcode = props.row._id; $refs.changeStatusRef.show()">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit Pickup Status</span>
              </b-dropdown-item>
              <b-dropdown-item @click="selectedBarcode = props.row._id; $refs.changeAddressRef.show(); newAddress = props.row.sampleCollectionData.address">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit Address</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <span v-else-if="props.column.field === 'reports'">
          <span
            v-if="props.row.reports"
            class="font-weight-bold text-danger"
          >
            <b-badge
              v-for="(report, key) in props.row.reports"
              :key="report"
              pill
              variant="light-primary"
              :href="report"
            >{{ key }}</b-badge>
          </span>
          <div v-else>
            No reports uploaded
          </div>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      ref="changeStatusRef"
      title="Change Pickup Status"
      @hide="selectedBarcode = null; selectedStatus = null;statusChangedAt = null"
      @cancel="selectedBarcode = null; selectedStatus = null;statusChangedAt = null"
      @ok="handleChangeKitPickupStatus"
    >
      <!-- TODO: format the ids -->
      <div class="py-1">
        <b>DU Barcode:</b> {{ rows.filter(row => row._id == selectedBarcode)[0] ? rows.filter(row => row._id == selectedBarcode)[0].duBarcode : '' }} <br>
      </div>
      <div class="py-1">
        <b>ST Barcode:</b> {{ rows.filter(row => row._id == selectedBarcode)[0] ? rows.filter(row => row._id == selectedBarcode)[0].stBarcode : '' }} <br>
      </div>
      <div class="py-1">
        <b>SA Barcode:</b> {{ rows.filter(row => row._id == selectedBarcode)[0] ? rows.filter(row => row._id == selectedBarcode)[0].saBarcode : '' }}
      </div>
      <div class="py-1">
        <b>COR Barcode:</b> {{ rows.filter(row => row._id == selectedBarcode)[0] ? rows.filter(row => row._id == selectedBarcode)[0].corBarcode : '' }}
      </div>
      <b-form-select
        v-model="selectedStatus"
        class="mb-1"
        :options="statusOptions"
      />
      <b-form-datepicker
        id="date"
        v-model="statusChangedAt"
        :value-as-date="true"
        class="mb-1 mt-2"
        :max="maxDate"
        placeholder="When was this completed?"
      />

    </b-modal>
    <b-modal
      id="modal-xl"
      ref="changeAddressRef"
      ok-title="Save"
      centered
      size="md"
      title="Edit Address"
      @ok="handleChangeAddress()"
      @hide="handleHide"
    >
      <b-card-text class="h-100 w-100">
        <validation-observer>
          <!-- street -->
          <b-form-group
            label="Street"
            label-for="street"
          >
            <validation-provider
              #default="{ errors }"
              name="Street"
              rules="required"
            >
              <b-form-input
                id="street"
                v-model="newAddress.street"
                :state="errors.length > 0 ? false:null"
                name="street"
                placeholder="Enter street name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- state -->
          <b-form-group
            label="State"
            label-for="state"
          >
            <validation-provider
              #default="{ errors }"
              name="State"
              rules="required"
            >
              <b-form-input
                id="state"
                v-model="newAddress.state"
                :state="errors.length > 0 ? false:null"
                name="state"
                placeholder="Enter state name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- city -->
          <b-form-group
            label="City"
            label-for="city"
          >
            <validation-provider
              #default="{ errors }"
              name="City"
              rules="required"
            >
              <b-form-input
                id="city"
                v-model="newAddress.city"
                :state="errors.length > 0 ? false:null"
                name="city"
                placeholder="Enter city name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- country -->
          <b-form-group
            label="Country"
            label-for="Country"
          >
            <validation-provider
              #default="{ errors }"
              name="Country"
              rules="required"
            >
              <b-form-input
                id="country"
                v-model="newAddress.country"
                :state="errors.length > 0 ? false:null"
                name="country"
                placeholder="Enter Country name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- pincode -->
          <b-form-group
            label="Pincode"
            label-for="pincode"
          >
            <validation-provider
              #default="{ errors }"
              name="Pincode"
              rules="required"
            >
              <b-form-input
                id="pincode"
                v-model="newAddress.pinCode"
                :state="errors.length > 0 ? false:null"
                name="pincode"
                placeholder="Enter pincode name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { formatDateToMonthShort } from '@/@core/utils/filter'
import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'
import moment from 'moment'

export default {
  components: {
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    return {
      pageLength: 20,
      columns: [
        {
          label: 'DU Barcode',
          field: 'duBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'ST Barcode',
          field: 'stBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'SA Barcode',
          field: 'saBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'COR Barcode',
          field: 'corBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Kit Type',
          field: 'kitType',
          formatFn: value => (value.trim().replace(/^\w/, c => c.toUpperCase())),
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Kit User',
          field: 'kitUser',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Account ID',
          field: 'accountID',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Kit Pickup Status',
          field: 'kitPickupStatus',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Date of sample collection',
          field: 'sampleCollectionData.sampleCollectionDate',
          formatFn: value => formatDateToMonthShort(value),
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Phone number',
          field: 'sampleCollectionData.phone',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Street',
          field: 'sampleCollectionData.address.street',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'State',
          field: 'sampleCollectionData.address.state',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'City',
          field: 'sampleCollectionData.address.city',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Country',
          field: 'country',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Pincode',
          field: 'sampleCollectionData.address.pinCode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Questionnaire Status',
          field: 'questionnaireStatus',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      selectedBarcode: null,
      selectedStatus: null,
      statusChangedAt: null,
      maxDate: today,
      statusOptions: [
        { text: 'Pickup scheduled', value: 'Scheduled' },
        { text: 'Received at Navipoint', value: 'Received' },
      ],
      newAddress: null,
      required,
    }
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    localTimestampToUtcTimestamp(timestamp) {
      return moment(moment.unix(timestamp).toDate()).utc(true).format('X')
    },
    handleChangeAddress() {
      this.showLoading()
      this.$http.put('barcodes/schedule', { _id: this.selectedBarcode, address: this.newAddress }).then().catch(this.handleError).finally(() => {
        this.closeLoading()
        this.selectedBarcode = null
        this.newAddress = {
          street: null,
          state: null,
          city: null,
          country: null,
          pinCode: null,
        }
      })
    },
    handleHide() {
      this.selectedBarcode = null
      this.newAddress = {
        street: null,
        state: null,
        city: null,
        country: null,
        pinCode: null,
      }
    },
    getTableData() {
      this.showLoading()
      this.$http.get('barcodes/schedule', { params: { queryParams: {} } }).then(response => {
        this.rows = response.data.list
      }).catch(this.handleError).finally(this.closeLoading)
    },
    handleChangeKitPickupStatus(bvModalEvent) {
      if (this.selectedStatus === null || this.statusChangedAt === null) {
        this.$bvToast.toast('All fields are required', {
          title: 'Incomplete details',
          variant: 'primary',
          solid: true,
        })
        return
      }
      bvModalEvent.preventDefault()
      this.showLoading()
      const dateObj = new Date(this.statusChangedAt)
      const timestamp = dateObj.getTime()
      this.$http.post('/kit/pickup', {
        _id: this.selectedBarcode,
        status: this.selectedStatus,
        statusChangedAt: parseInt(this.localTimestampToUtcTimestamp(timestamp) / 1000, 10),

      }).then(() => {
        this.getTableData()
        this.$bvToast.toast('Barcode status changed Successfully!', {
          title: 'Task Complete',
          variant: 'success',
          solid: true,
        })
      }).catch(this.handleError).finally(() => {
        this.closeLoading()
        this.$refs.changeStatusRef.hide()
        // this.selectedStatus = null
        // this.selectedBarcode = null
      })
    },

  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
